dl.metric {
  margin-top: 5em;

  dt {
    text-transform: uppercase;
    color: #666;
  }

  dd {
    font-size: 2.4em;
    color: #333;
    margin: 0;
  }
}
