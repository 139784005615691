.top-toast {
  position: relative;
  top: 0;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  text-transform: uppercase;
  font-weight: lighter;
  color: #333;
  cursor: pointer;

  span {
    font-size: 11px;
    display: block;
    color: #666;
  }
}
