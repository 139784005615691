body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


dl.metric {
  margin-top: 5em; }
  dl.metric dt {
    text-transform: uppercase;
    color: #666; }
  dl.metric dd {
    font-size: 2.4em;
    color: #333;
    margin: 0; }

.top-toast {
  position: relative;
  top: 0;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  text-transform: uppercase;
  font-weight: lighter;
  color: #333;
  cursor: pointer; }
  .top-toast span {
    font-size: 11px;
    display: block;
    color: #666; }

